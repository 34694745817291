.portfolio-container {
    width:100%;
    height:fit-content;
    display: flex;
    justify-content: stretch;
    flex-wrap: wrap;
    gap: 40px;
}

.image-list-item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
}

.highlight {
    height: 80vh;
    object-fit: cover;
    /* flex: 1 1 auto; */
}

.more {
    height: 80vh;
    display: grid;
    object-fit: cover;
    grid-template-rows: 2fr 1fr;
    gap: 64px;
}

.next-project {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgb(228, 228, 228);
    cursor: pointer;
}

.next-project:hover {
    background-color: rgb(216, 238, 249);
    
}