.profile-container {
    flex: 1 1 0px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
    background-color: rgba(var(--taupe), 0.2);
}

.profile-name {
    color: rgb((--grey));
    font-weight: 400;
}

.age {
    color: rgb(var(--grey));
}

.points {
}

ul {
    margin: 0px;
}