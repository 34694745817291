.step-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.step-name{
    text-transform: uppercase;
    margin: 0px;
}

.step{
    background-color: rgb(var(--grey));
    color: rgb(var(--base));
    width: 85px;
    height: 85px;
    border: 5px solid rgb(var(--charcoal));
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    margin: 0px;
}

.methods{
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
}