
.home {
    width: 100%;
    height: 100%;
    font-family: 'Outfit';
    color: rgb(var(--charcoal));
}

@media screen and (max-width: 480px){
    .section {
      padding-left: 16px; 
      padding-right: 16px; 
    }
  }
  
  @media screen and (max-width: 768px) and (min-width: 481px) {
    .section {
      padding-left: 32px; 
      padding-right: 32px; 
    }
  
  }
  
  @media screen and (min-width: 769px){
    .section {
      padding-left: 192px; 
      padding-right: 192px; 
    }
  
  }

.section {
    display: flex;
    justify-content: center;
} 

.section-content {
    z-index: 1;
    grid-column: 1;
    grid-row: 1;
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    gap: 64px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-top: 60px;
    padding-bottom: 60px;
    box-sizing: border-box;
    height: auto;
}

.herosection {
    display: flex;
    flex-direction: column;
    font-size: 24px;
    max-width: 60%;
    min-width: 350px;
    min-height: 50vh;
    align-items: start;
    justify-content: center;
    gap: 20px;
    font-weight: 300;
    line-height: 140%;
    padding-top: 80px;
}

.sub-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.sub-section-title {
    text-transform: uppercase;
    font-weight: 300;
}

.flex-wrap {
    display: flex;
    justify-content: stretch;
    flex-wrap: wrap;
    gap: 40px;
}

.dark-header {
    flex: 1 1 0px;
    min-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(var(--grey));
    color: rgb(var(--base));
    border-radius: 8px;
    padding: 24px;
    max-height: 27px;
}

.base-card, .base-card-2, .base-card-3, .base-card-4 {
    flex: 1 1 0px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px;
    border: 1px solid rgb(var(--grey));
    border-radius: 8px;
    background-color: rgba(var(--base), 0.5);
}

.base-card-2 {
    background-color: white;
}

.base-card-3 {
    background-color: rgb(var(--moss-light));
    flex: 0 0 0;
}

.base-card-4 {
    background-color: rgba(var(--taupe), 0.3);
    border: none;
}

#card-4-selected {
    background-color: rgba(var(--taupe), 0.5);
    border: 5px solid rgb(var(--charcoal));
}

#card-selected {
    border: 5px solid rgb(var(--moss));

}

.two-column {
    column-count: 2;
    text-align: justify;
    column-width: 300px;
    break-inside: avoid;
}

.half {
    min-width: 300px;
    flex: 1 1 0px;
    object-fit:contain;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.full-screen {
    width: 100%;
    object-fit: contain;
}

.screen-highlight {
    flex: 1 1 0px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.annotation {
    width: 100%;
    display: flex;
    gap: 6px;
    justify-content: center;
    margin-bottom: 60px;
}

.stats {
    min-width: 300px;
    flex: 1 1 0px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;

}

.limit-width {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    gap: 48px;
}

#project {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 60px;
}

#design-process {
    background-color: rgb(var(--taupe));
    overflow: hidden;
}

.design-process-timeline {
    width:100%;
    min-width: 950px;
    display: flex;
    justify-content: stretch;
}

#solution {
    background-color: rgb(var(--taupe));
}

#wireflow {
    background-color: rgb(var(--base));
}

#interactive-prototype {
    background-color: rgb(var(--base));
}

#terracota-highlight {
    margin: 0;
    color: rgb(var(--terracota));
}

#link {
    color: rgb(var(--terracota));
    font-weight: 600;
    text-decoration: underline;
}

b {
    font-weight: 600;
}

h1 {
    font-family: 'Oswald';
    font-size: 43px;
    line-height: 120%;
    font-weight: 400;
    margin: 0px;
}

h2 {
    font-family: 'Oswald';
    font-size: 40px;
    line-height: 120%;
    font-weight: 400;
    margin: 0px;
}

h3 {
    font-family: 'Oswald';
    font-size: 32px;
    line-height: 120%;
    font-weight: 400;
    margin: 0px;
}

h4 {
    font-family: 'Oswald';
    font-size: 24px;
    font-weight: 400;
    margin: 0px;
}

h5 {
    font-family: 'Oswald';
    font-size: 18px;
    line-height: 120%;
    font-weight: 400;
    margin: 0px;
}

h6 {
    font-family: 'Oswald';
    font-size: 14px;
    line-height: 120%;
    font-weight: 400;
    margin: 0px;
}

b1 {
    font-family: 'Outfit';
    font-size: 32px;
    line-height: 120%;
    font-weight: 400;
    margin: 0px;
}

b2 {
    font-family: 'Outfit';
    font-size: 24px;
    line-height: 120%;
    font-weight: 400;
    text-align: justify;
    margin: 0px;
}

b3 {
    font-family: 'Outfit';
    font-size: 18px;
    line-height: 140%;
    font-weight: 300;
    text-align: justify;
    margin: 0px;
}

b4 {
    font-family: 'Outfit';
    font-size: 14px;
    line-height: 120%;
    font-weight: 300;
    letter-spacing: 0.2px;
    margin: 0px;
}

b5 {
    font-family: 'Outfit';
    font-size: 10px;
    line-height: 120%;
    font-weight: 400;
    margin: 0px;
}

:root {
    --white: 255, 255, 255;
    --black: 0, 0, 0;
    --base: 255, 252, 242;
    --taupe: 204, 197, 185;
    --grey: 64, 61, 57;
    --charcoal: 37, 36, 34;
    --moss-light: 160, 167, 136;
    --moss: 96, 108, 56;
    --moss-dark: 58, 65, 34;
    --terracota-light: 215, 167, 124;
    --terracota: 188, 108, 37;
    --terracota-dark: 113, 65, 22;
} 
