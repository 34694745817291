#pilot-ideation {
    background-color: rgb(var(--base));
}

#pilot-summary {
    background-color: rgb(var(--base));
}

.iterations {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

#iterations {
    flex: 1 1 0px;
    justify-content: space-between;
    gap:12px;
}

#final {
    flex: 2 1 0px;
}

.page-details {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 40px;
}