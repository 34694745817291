.highlight {
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.highlight-term {
    color: rgb(var(--terracota));
    font-weight: 500;
}

.heuristics {
    width: 100%;
    display: flex;
    gap: 16px;
    justify-content: stretch;
    flex-wrap: wrap;
}

.h-column{
    flex: 1 1 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 300px;
}

.feedback {
    display: flex;
    gap: 8px;
    align-items: center;
}

img {
    width: 100%;
}

