.maintenance {
    width: 100%;
    height: 100vh;
    font-family: 'Oswald';
    display: flex;
    align-items: center;
}

@media screen and (max-width: 480px){
    .maintenance {
        flex-direction: column;
        justify-content: center;
    }

    .left-title {
        width: 100%;
        font-size: 57px;
        font-weight: 500;
    }

    .right-message {
        width: 100%;
        font-size: 24px;
    } 
}

@media screen and (max-width: 768px) {
    .maintenance {
        flex-direction: column;
        justify-content: center;
    }

    .left-title {
        width: 100%;
        font-size: 57px;
        font-weight: 500;
    }

    .right-message {
        width: 100%;
        font-size: 24px;
    } 
}

@media screen and (min-width: 769px){
    .left-title {
        width: 50%;
        min-width: 300px;
        font-size: 57px;
        font-weight: 500;
    }

    .right-message {
        width: 50%;
        min-width: 300px;
        font-size: 24px;
    } 
}


.unbreakable-text {
    display: inline-block;
    white-space: nowrap;
}