.thumbnail-container {
    flex: 1 1 0px;
    max-height: 100%;
    min-width: 300px;
    filter: grayscale(100%);
    cursor: pointer;
    transition: filter 0.2s ease-in-out;
}

.thumbnail {
    display: grid;
    border-radius: 8px;
    width: 100%;
    height: 100%;
}

.thumbnail-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    grid-column: 1;
    grid-row: 1;
    border-radius: 8px;
}

.thumbnail-container:hover {
    filter: none;
}

.thumbnail:hover .thumbnail-hover{
    transition: opacity 5s ease-in-out;
    z-index: 3;
    background: linear-gradient(to top, black, transparent);
    grid-column: 1;
    grid-row: 1;
    display: flex;
    align-items: end;
    color: white;
    padding: 24px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    opacity: 1;
}

.thumbnail-hover {
    transition: opacity 5s ease-in-out;
    display: none;
    opacity: 0;
}
