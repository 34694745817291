body {
  height: 100%; 
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @media screen and (max-width: 480px){
  body {
    padding-left: 16px; 
    padding-right: 16px; 
  }
}

@media screen and (max-width: 768px) and (min-width: 481px) {
  body {
    padding-left: 32px; 
    padding-right: 32px; 
  }

}

@media screen and (min-width: 769px){
  body {
    padding-left: 192px; 
    padding-right: 192px; 
  }

} */


/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
