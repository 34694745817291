#profile {
    width:100%;
    /* max-width: 400px; */
    border-radius: 8px;
    flex: 0 0 0px;
}

#profile-desc {
    width: 100%;
    justify-content: center;
    flex: 1 1 0px;
}

#experience{
    background-color: rgb(var(--taupe));
    gap: 60px;
}

.exp-title {
    text-transform: uppercase;
    color: white;
}

.company {
    font-weight: 600;
    color: white;
    display: flex;
    gap: 16px;
    align-items: baseline;
    font-size: 20px;
}

.job {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.jobs {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

#professional {
    gap: 16px;
    flex: 3 1 0px;
}

#education {
    gap: 16px;
    flex: 2 1 0px;
}

#stack {
    background-color: rgb(var(--base));
    gap: 60px;
}

.skill {
    display: flex;
    align-items: center;
    gap: 4px;
    min-width: 87px;
}

#skill-column {
    min-width: 0px; 
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.skills-columns {
    display: flex;
    gap: 16px;
}

.skills-title {
    text-transform: uppercase;
    font-weight: 400;
    color: rgb(var(--moss));
}

.skill-card {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 1 0px;
    padding: 8px;
}

#interests {
    gap: 4px;
}

.interest {
    text-transform: uppercase;
    color: rgb(var(--moss));
    background-color: rgb(var(--moss-light));
    padding: 8px;
    border-radius: 4px;
}

.icon-img {
    flex: 1 1 0px;
    object-fit: contain;
    /* max-width: 50px; */
}