@media screen and (max-width: 480px){
    .menu-bar-container {
      padding-left: 16px; 
      padding-right: 16px; 
    }
  }
  
  @media screen and (max-width: 768px) and (min-width: 481px) {
    .menu-bar-container {
      padding-left: 32px; 
      padding-right: 32px; 
    }
  
  }
  
  @media screen and (min-width: 769px){
    .menu-bar-container {
      padding-left: 192px; 
      padding-right: 192px; 
    }
  
  }


.menu-bar-container {
    position: fixed;
    background-color: rgba(255, 255, 255);
    display: flex;
    justify-content: center;
    height: 80px;
    z-index: 30;
    left: 0;
    right: 0;
}

.menu-bar {
  width: 100%;
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Oswald';
  font-weight: 400;
  font-size: 32px;
}

.menu-buttons {
    height: 100%;
    display: flex;
    gap: 16px;
    font-size: 24px;
    align-items: center;
}

.home-button {
    font-weight: 500;
}

.home-button, .menu-button {
    display: flex;
    height: fit-content;
    margin: 0;
    cursor: pointer;
    align-items:flex-end;
    padding-bottom: 2px;
    box-sizing: border-box;
}

.menu-button:hover, #selected {
    padding-bottom: 0px;
    border-bottom: 2px solid rgb(var(--charcoal));
}

a {
    color: rgb(var(--charcoal));
    text-decoration: none;
}