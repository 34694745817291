
  
@media screen and (max-width: 1119px) {

    #sonicLink {
        display: grid;
        background-color: rgb(var(--charcoal));
        color: rgb(var(--white));
        z-index: -2;
        background-image: url("../images/SonicLink/catenary.jpg");
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
        overflow: hidden;
    }

    .overlayCharcoal {
        grid-column: 1;
        grid-row: 1;
        background-color: rgb(var(--charcoal));
        height: 85%;
        width: 200%;
        position: relative;
        left: -200px;
    }

}

@media screen and (min-width: 1120px){
    #sonicLink {
        display: grid;
        background-color: rgb(var(--charcoal));
        color: rgb(var(--white));
        background-image: url("../images/SonicLink/catenary.jpg");
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
    }

    .overlayCharcoal {
        grid-column: 1;
        grid-row: 1;
        background-color: rgb(var(--charcoal));
        height: 100%;
        width: 90%;
        position: relative;
        left: -200px;
    }

}

#design-process-2 {
    background-color: rgb(var(--base));
}

#videos {
    position: relative;
    padding-bottom: 56.25%;
}

iframe{
    position: absolute;
    top: 0;
    left: 0; 
    width: 100%;
    height: 100%;
}

#concept {
    background-color: rgb(var(--base));
}

#sonic-evaluation {
    background-color: rgb(var(--taupe));
}

.flex-grid {
    max-width: 100%;
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: masonry; */
}

.moodboard-item {
    width: 100%;
    object-fit: cover;
}

.moodboard-item2 {
    width: 100%;
    object-fit: cover;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
}

.wireframe {
    flex: 1 1 0px;
    object-fit: contain;
    border: 2px solid rgb(var(--charcoal));
    box-sizing: border-box;
    width: 290px;
}

#sonic-flex {
    gap: 24px;
}

#sonic-half {
    gap: 16px;
}