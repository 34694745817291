#contacts {
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}   

#contact-icons {
    max-height: 50vh;
}

.icon-img {
    flex: 1 1 0px;
    object-fit: contain;
    max-width: 50px;
}